@import "../../styles/variables";

.button {
  // @extend %quantico;

  position: relative;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  line-height: 1;
  white-space: nowrap;
  border: 2px solid transparent;
  border-radius: 100px;
  box-shadow: none;
  background: none;
  outline: none;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;

  &:hover {
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    cursor: pointer;
  }

  &:disabled,
  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  & > span {
    margin-left: 9px;
    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  // Width
  &.full {
    display: flex;
    width: 100%;
  }

  &.auto {
    display: inline-flex;
  }

  // Large Size
  &.large {
    // height: 56px;
    // min-height: 56px;
    padding: 16px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;

    &.iconOnly {
      width: 56px;
      min-width: 56px;
      height: 56px;
      padding: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  // Medium Size
  &.medium {
    height: 44px;
    min-height: 44px;
    padding: 0 15px;
    font-size: 14px;

    &.iconOnly {
      width: 44px;
      min-width: 44px;
      height: 44px;
      padding: 0;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  // Small Size
  &.small {
    height: 36px;
    min-height: 36px;
    padding: 0 15px;
    font-size: 14px;

    &.iconOnly {
      width: 36px;
      min-width: 36px;
      height: 36px;
      padding: 0;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  // X-Small Size
  &.x-small {
    height: 30px;
    min-height: 30px;
    padding: 0 7px;
    font-size: 12px;

    svg {
      width: 10px;
      min-width: 10px;
      height: 10px;
    }

    &.iconOnly {
      width: 30px;
      height: 30px;
      padding: 0;
    }
  }

  // Primary Theme
  &.primary {
    color: $white;
    border: none;
    background: $primary;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    &:hover {
      background: $primary;
      opacity: 0.8;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
    }

    &.destructive {
      color: $white;
      background: $burnt-sienna none;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;

      svg {
        fill: $white;
      }
    }

    &:disabled,
    &.disabled {
      color: $spun-pearl;
      background: $jumbo none;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;

      svg {
        fill: $spun-pearl;
      }
    }

    svg {
      fill: $mirage;
    }

  }

  // Secondary Theme
  &.secondary {
    color: $primary;
    // border-left-color: $lightning1;
    // border-top-color: $lightning1;
    // border-right-color: $lightning2;
    // border-bottom-color: $lightning2;
    border-color: $primary;

    &.destructive {
      color: $burnt-sienna;
      border-color: $burnt-sienna;

      svg {
        fill: $burnt-sienna;
      }
    }

    &:disabled,
    &.disabled {
      border-color: $white;
    }

    svg {
      fill: $primary;
    }
  }

  // Tertiary Theme
  &.tertiary {
    color: $spun-pearl;
    border-color: $jumbo;

    &:hover {
      color: $white;
      border-left-color: $lightning1;
      border-top-color: $lightning1;
      border-right-color: $lightning2;
      border-bottom-color: $lightning2;

      svg {
        fill: $white;
      }
    }

    &:focus {
      color: $white;
      border-color: $jumbo;

      svg {
        fill: $white;
      }
    }

    &:active {
      color: $mirage;
      border-color: $jumbo;
      background: $lightning-yellow;

      svg {
        fill: $mirage;
      }
    }

    &.destructive {
      color: $burnt-sienna;
      border-color: $burnt-sienna;

      svg {
        fill: $burnt-sienna;
      }

      &:hover {
        background: none;
      }
    }

    &:disabled,
    &.disabled {
      color: $spun-pearl;
      border-color: $jumbo;

      svg {
        fill: $spun-pearl;
      }

      &:active {
        background: none;
      }
    }

    svg {
      fill: $spun-pearl;
    }
  }

  // Transparent Theme
  &.transparent {
    color: $spun-pearl;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    svg {
      fill: $spun-pearl;
    }

    &:hover {
      color: $white;
      background-color: $trout;

      svg {
        fill: $white;
      }
    }

    &:focus {
      color: $white;

      svg {
        fill: $white;
      }
    }

    &:active {
      color: $white;
      border-color: $jumbo;

      svg {
        fill: $white;
      }
    }

    &.destructive {
      color: $burnt-sienna;

      svg {
        fill: $burnt-sienna;
      }
    }

    &:disabled,
    &.disabled {
      color: $jumbo;

      svg {
        fill: $jumbo;
      }

      &:hover {
        background: none;
      }
    }
  }

  // Icon and Icon Position
  &__Icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      // width: 13px;
      // height: 13px;
      width: 24px;
      height: 24px;
      transition: all 0.3s ease-in-out;
    }
  }

  &.right {
    flex-direction: row-reverse;
  }
}

@import '../../styles/variables';

.customer-portal {
  .container {
    .left-container {
      background: url(../../assets/images/customer-portal-img.png) no-repeat;
      background-position: -65px 80%;
      background-size: contain;
    }
  }
}

input[type='checkbox'] {
  accent-color: $primary;
  border-color: $primary;
  border: 2px solid #15049e !important;

  &.focus\:ring-2:focus {
    box-shadow: none !important;
  }
}



.googleSignupButton{
    border: 1px solid #06C290 !important;
    border-radius: 30px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 19px;
    color: #FFFFFF !important;
    background-color: transparent !important;
    margin: 20px 0 !important;
    position:  relative !important;
}
/* .googleSignupButton:hover{
background: blue !important;
} */


.mainContent{
    background-color: #060B07;
    text-align: center;
}

.signupContainer{
    width: 400px;
    margin: 0 auto;
    padding: 145px 15px 15px 15px;
}

.buttonText{
    text-align: center;
    width: 100%;
    display: block;
}


.signupContainer .googleSignupButton svg{ 
    position: absolute !important;
    top: 12px;
}

.createAccount{
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 100px;
}

.bottomPara{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    max-width: 317px;
}

.signUpEmailButton{
    display: block;
    border: 1px solid #06C290 !important;
    border-radius: 30px !important;
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 19px;
    color: #FFFFFF !important;
    background-color: transparent !important;
    position:  relative !important;
    padding: 11px 10px;
    width: 390px;
    align-items: center;
}

/* .signUpEmailButton:hover{
    background-color: blue !important;
} */

.opt-container input {
    background-color: #06c2905a;
    /* opacity: 0.6; */
    border-radius: 10px;
    min-height: 65px;
    color: white;
    font-size: large;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none;
}

/* @layer base {
  html {
    font-family: Proxima Nova, system-ui, sans-serif;
  }
} */

/* @layer base {
  @font-face {
    font-family: ProximaNova;
    font-weight: 400;
    src: url(./assets/fonts/proximanova-semibold.ttf)format("ttf");
  }
  @font-face {
    font-family: ProximaNova;
    font-weight: 500;
    src: url(./assets/fonts/proximanova-semibold-2.ttf) format("ttf");
  }
}

body {
  font-family: 'ProximaNova', sans-serif;
} */

/* @font-face {
  font-family: 'Proxima Nova Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/proximanova-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/proximanova-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/proximanova-semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Soehne';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/soehne-breit-kraftig.ttf') format('truetype');
} */


@font-face {
  font-family: 'Aeonik Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/daylight-fonts/Aeonik-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik Bold';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/daylight-fonts/Aeonik-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Aeonik Light';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/daylight-fonts/Aeonik-Light.otf') format('opentype');
}